import React from 'react'

import Layout from '../layouts/offertLayout'
import {StyledHeader, StyledCategory, StyledContent} from '../layouts/layoutStyles'

import NaszeRealizacje from "../components/NaszeRealizacje"

import zdj from '../images/headerPhotos/zewn.jpg'

const offert= ({data}) => {
  return(
    <Layout 
      headerPhoto={<img src={zdj} alt="naprawa"/>} 
      prefooterElement={<NaszeRealizacje/>}
    >
      <StyledHeader>BETONY ZEWNĘTRZNE</StyledHeader>
      <StyledCategory>Czesanka</StyledCategory>
      <StyledContent>
        To rodzaj betonu antypoślizgowego stosowanego najczęściej na zewnątrz, przy rampach
        rozładunkowych czy zatoczkach autobusowych. Czesanka wykonywana jest najczęściej na
        betonie drogowo-mostowym. Przy użyciu tzw. mioteł w betonie rzeźbi się bieżnik przez
        co powierzchnia staje się antypoślizgowa.
      </StyledContent>
      <StyledCategory>Betony typu decor z kruszywem odkrytym</StyledCategory>
      <StyledContent>
        Rodzaj betonu, gdzie odkryte kruszywo tworzy antypoślizgową powierzchnię. Jest to jeden
        z betonów stosowanych na zewnątrz, co za tym idzie, przy tworzeniu powierzchni stosowany
        jest beton drogowo-mostowy.
      </StyledContent> 
    </Layout>
  )
}

export default offert